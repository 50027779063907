<template>
    <v-app-bar app clipped-left color="primary">
        <div
            class="title ml-3 mr-5 mt-2 hidden-sm-and-down align-center d-flex"
        >
            <span>
                <img :src="$config('app.appLogoIcon')" />
            </span>
            <span class="ml-2">
                <img
                    :src="$config('app.appLogoText')"
                    class="mt-1"
                    height="35"
                />
            </span>
        </div>
        <v-spacer></v-spacer>
        <div class="text-xs-right">
            <h4 class="white--text">{{ title }}</h4>
        </div>
    </v-app-bar>
</template>
<script>
export default {
    name: "print-layout-app-header",
    props: {
        title: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {};
    },
    methods: {},
    mounted() {},
};
</script>

<style scoped>
</style>