var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { app: "", "clipped-left": "", color: "primary" } },
    [
      _c(
        "div",
        {
          staticClass:
            "title ml-3 mr-5 mt-2 hidden-sm-and-down align-center d-flex"
        },
        [
          _c("span", [
            _c("img", { attrs: { src: _vm.$config("app.appLogoIcon") } })
          ]),
          _c("span", { staticClass: "ml-2" }, [
            _c("img", {
              staticClass: "mt-1",
              attrs: { src: _vm.$config("app.appLogoText"), height: "35" }
            })
          ])
        ]
      ),
      _c("v-spacer"),
      _c("div", { staticClass: "text-xs-right" }, [
        _c("h4", { staticClass: "white--text" }, [_vm._v(_vm._s(_vm.title))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }